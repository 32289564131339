import { Link } from "gatsby"

import React from "react"
import styled from "styled-components"
import { palette } from "../_variables"
import { respondTo } from "../_respondTo"

import CookieConsent from "react-cookie-consent"

const FooterWrapper = styled.footer`
  width: 100%;
  background-color: #000000;
  text-align: center;
  padding: 0.75em 0.75em 2em 0.75em;

  ${respondTo.md`
    padding: 1em 10vw 
  `}
`

const FooterText = styled.p`
  color: white;
  margin: 0 auto 1em auto;
  font-size: small;
  line-height: 1.3;
`

const FooterLinkWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 960px;
  margin: 0 auto;
  color: white;
`

const FooterLink = styled(Link)`
  color: white;
  font-size: small;
`

function Header() {
  return (
    <FooterWrapper>
      <FooterLinkWrapper style={{ marginBottom: "1.5em" }}>
        <FooterLink to={"/important-information"}>
          Important Information
        </FooterLink>
        <FooterLink to={"/privacy-policy"}>Privacy Policy</FooterLink>
        <FooterLink to={"/privacy-policy#cookies"}>Cookie Policy</FooterLink>

        <FooterLink to={"/complaints-policy"}>Complaints Policy</FooterLink>

        <FooterLink to={"/important-information#fos"}>
          Financial Ombudsman Service
        </FooterLink>
      </FooterLinkWrapper>

      <FooterText>
        Copyright ©2021 Cutter &amp; Co Financial Planning Limited.
      </FooterText>

      <FooterText>
        Cutter &amp; Co Financial Planning Limited is registered in England and
        Wales with company number 11281160. Registered office is located at
        Stonyroyd House, 8 Cumberland Road, Leeds, West Yorkshire LS6 2EF.
        Cutter &amp; Co Financial Planning Limited is an appointed
        representative of Navigator Financial Planning Limited which is
        authorised and regulated by the Financial Conduct Authority. Our FCA
        number is 805876.
      </FooterText>

      <FooterText>
        Both Cutter &amp; Co Financial Planning and Navigator Financial Planning
        are part of the{" "}
        <FooterLink to="/true-wealth-group">True Wealth Group</FooterLink>, which is part of the Succession Wealth Group which is part of the Aviva Group of Companies and is authorised and regulated by the Financial Conduct Authority.
      </FooterText>

      <CookieConsent
        style={{
          fontSize: "0.75em",
          padding: "0.05em",
          backgroundColor: "rgba(115,115,115,0.85)",
        }}
        buttonStyle={{ margin: "0 auto", padding: "2 5", order: "0" }}
        contentStyle={{ margin: "0" }}
        expires={365}
      >
        This website uses cookies to monitor its use.
      </CookieConsent>
    </FooterWrapper>
  )
}

export default Header
